import { withTranslation, WithTranslation } from "react-i18next"

interface InnovationsPropsType extends WithTranslation {
  scrollToSection: (ref: React.RefObject<null> | React.RefObject<HTMLDivElement>, offset: number) => void;
  ref: React.RefObject<HTMLDivElement> | React.RefObject<null>;
}

const Innovations = ({ref, scrollToSection, t}: InnovationsPropsType) => {
  return (
    <div className="innovations">
        <div className="innovations__image"/>
        <div className="innovations__wrapper">
            <h2 className="innovations__title">{t("innovation")}</h2>
            <button className="innovations__button" onClick={() => scrollToSection(ref, 80)}>{t("contact")}</button>
        </div>
    </div>
  )
}

export default withTranslation()(Innovations);