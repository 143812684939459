import logo from "../../assets/logo.png";
import poland from "../../assets/poland.png";
import unitedKingdom from "../../assets/united-kingdom.png";
import hamburger from "../../assets/hamburger.png";
import { useState } from "react";
import Menu from "../Menu/Menu";
import i18n from "../../i18n";

interface NavigationPropsType {
  entryRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  aboutUsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  reviewsRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  contactRef: React.RefObject<HTMLDivElement> | React.RefObject<null>;
  scrollToSection: (ref: React.RefObject<null> | React.RefObject<HTMLDivElement>) => void;
  openModal: () => void;
}

const Navigation = ({ entryRef, aboutUsRef, reviewsRef, contactRef, scrollToSection, openModal}: NavigationPropsType) => {
  const [isMenu, setIsMenu] = useState<boolean>(false);

  const menu = () => {
    setIsMenu((prev: boolean) => !prev);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="navigation">
      <Menu {...{isMenu, entryRef, aboutUsRef, reviewsRef, contactRef, scrollToSection, openModal}} />
      <img className="navigation__logo" src={logo} alt="logo" />
      <div className="navigation__box">
        <button className="navigation__button" onClick={() => changeLanguage("pl")}>
          <img className="navigation__icon" src={poland} alt="poland" />
        </button>
        <button className="navigation__button" onClick={() => changeLanguage("en")}>
          <img className="navigation__icon" src={unitedKingdom} alt="unitedKingdom"/>
        </button>
        <button className="navigation__buttonMenu" onClick={menu}>
          <img className="navigation__iconHamburger" src={hamburger} alt="hamburger"/>
        </button>
      </div>
    </div>
  );
};

export default Navigation;
