import React from 'react';
import { useCookies } from 'react-cookie';

const Cookies = () => {
  const [cookies, setCookie] = useCookies(['cookie-consent']);

  const acceptCookies = () => {
    setCookie('cookie-consent', 'accepted', { path: '/', maxAge: 365 * 24 * 60 * 60 });
  };

  if (cookies['cookie-consent'] === 'accepted') {
    return null;
  }

  return (
    <div className="cookie">
      <div className="cookie__content">
        <p className="cookie__text">
          Należy pamiętać, że ta strona używa plików cookie. Aby zaakceptować naszą Politykę prywatności,
          kliknij przycisk „Akceptuj” lub kontynuuj przeglądanie. Zgadzam się.
        </p>
        <button className="cookie__button" onClick={acceptCookies}>
          Akceptuj
        </button>
      </div>
    </div>
  );
};

export default Cookies;

