import React, { useState } from "react";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import Entry from "./components/Entry/Entry";
import Innovations from "./components/Innovations/Innovations";
import Navigation from "./components/Navigation/Navigation";
import Reviews from "./components/Reviews/Reviews";
import Skeleton from "./components/Skeleton/Skeleton";
import General from "./components/General/General";
import Statistics from "./components/Statistics/Statistics";
import PriceList from "./components/PriceList/PriceList";
import Cookies from "./components/Cookies/Cookies";

const App = () => {
  const entryRef = React.useRef(null);
  const aboutUsRef = React.useRef(null);
  const reviewsRef = React.useRef(null);
  const contactRef = React.useRef(null);

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const scrollToSection = (
    sectionRef: React.RefObject<HTMLDivElement> | React.RefObject<null>
  ) => {
    if (sectionRef.current) {
      const top =
        sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: top - 80,
        behavior: "smooth",
      });
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <Skeleton>
      <PriceList modalIsOpen={modalIsOpen} closeModal={closeModal} />
      <Navigation
        scrollToSection={scrollToSection}
        entryRef={entryRef}
        aboutUsRef={aboutUsRef}
        reviewsRef={reviewsRef}
        contactRef={contactRef}
        openModal={openModal}
      />
      <Entry ref={entryRef} />
      <AboutUs ref={aboutUsRef} />
      <Statistics />
      <General />
      <Innovations ref={contactRef} scrollToSection={scrollToSection} />
      <Reviews ref={reviewsRef} />
      <Contact
        scrollToSection={scrollToSection}
        entryRef={entryRef}
        aboutUsRef={aboutUsRef}
        reviewsRef={reviewsRef}
        contactRef={contactRef}
        openModal={openModal}
      />
      <Cookies />
    </Skeleton>
  );
};

export default App;
