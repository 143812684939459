import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Modal from "react-modal";

Modal.setAppElement("#root");

interface PriceListPropsType extends WithTranslation {
  modalIsOpen: boolean;
  closeModal: () => void;
}

const PriceList = ({ t, modalIsOpen, closeModal }: PriceListPropsType) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      maxWidth: "800px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
      zIndex: 1001,
      maxHeight: "60vh",
      overflowY: "auto" as "auto",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Cennik Modal"
    >
      <div className="priceList">
        <button className="priceList__closeButton" onClick={closeModal}>
          ✕
        </button>
        <h2 className="priceList__title">{t("price")}</h2>
        <p className="priceList__description">
          {t(
            "priceDescription"
          )}
        </p>
        <div className="priceList__container">
          <div className="priceList__box">
            <div className="priceList__boxChild">
              <p className="priceList__text">{t("easyPage")}</p>
              <p className="priceList__textPrice">10 000 - 18 000 PLN</p>
            </div>
            <div className="priceList__boxChild">
              <p className="priceList__text">
                {t("mvp")}
              </p>
              <p className="priceList__textPrice">30 000 - 50 000 PLN</p>
            </div>
            <div className="priceList__boxChild">
              <p className="priceList__text">
                {t("advanced")}
              </p>
              <p className="priceList__textPrice priceList__textPrice--up">500 000 PLN &uarr;</p>
            </div>
          </div>
          <div className="priceList__box">
            <div className="priceList__boxChild">
              <p className="priceList__text">{t("digitial")}</p>
              <p className="priceList__textPrice">3 000 - 8 000 PLN</p>
            </div>
            <div className="priceList__boxChild">
              <p className="priceList__text">{t("business")}</p>
              <p className="priceList__textPrice">0 - 100 PLN</p>
            </div>
            <div className="priceList__boxChild">
              <p className="priceList__text">{t("consultations")}</p>
              <p className="priceList__textPrice">200 - 500 PLN</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(PriceList);
