import { withTranslation, WithTranslation } from "react-i18next";
import products from "../../assets/products.png";
import ai from "../../assets/ai.png";

interface EntryPropsType extends WithTranslation {
  ref: React.RefObject<HTMLDivElement> | React.RefObject<null>;
}

const Entry = ({ t, ref }: EntryPropsType) => {
  return (
    <div className="entry" ref={ref}>
      <h1 className="entry__title">{t("support")}</h1>
      <p className="entry__desciption">{t("mainDescription")}</p>
      <div className="entry__box">
        <div className="entry__boxChild">
          <a
            className="entry__link"
            href="https://shipculture-eae76.web.app/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="entry__icon" src={products} alt="products" />
            {t("crm")}
          </a>
          <a
            className="entry__link"
            href="/"
            rel="noreferrer"
          >
            <img className="entry__iconTwo" src={ai} alt="ai" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Entry);
